<template>
  <div>
    <!--    <div v-if="$screenIsSmall()">-->
    <!--      <div id="datos-fotos">-->
    <!--        <v-form ref="form" v-model="valid" lazy-validation>-->
    <!--          <v-main>-->
    <!--            <v-row>-->
    <!--              <v-col-->
    <!--                align="center"-->
    <!--                justify="center"-->
    <!--              >-->
    <!--                <div>-->
    <!--                  <p>Validá tu identidad de una manera muy fácil.</p>-->
    <!--                  <p>Solo necesitamos las siguientes fotos:</p>-->
    <!--                  <ol class="listItems">-->
    <!--                    <li class="liMainData" v-show="takeDni">Frente del DNI.</li>-->
    <!--                    <li class="liMainData" v-show="takeDni">Parte de atrás del DNI.</li>-->
    <!--                    <li class="liMainData" v-show="takeSelfie">Selfie.</li>-->
    <!--                  </ol>-->
    <!--                  <p class="subtitle">PARA TOMAR LAS FOTOS TE PEDIREMOS ACCESO A LA CÁMARA.</p>-->
    <!--                </div>-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--          </v-main>-->
    <!--        </v-form>-->

    <!--        <v-footer app>-->
    <!--          <v-row>-->
    <!--            <v-col-->
    <!--              align="center"-->
    <!--              justify="center"-->
    <!--            >-->
    <!--              <v-btn bottom depressed rounded class="btnAmarillo" @click="next()">Continuar</v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->

    <!--          <v-row>-->
    <!--            <v-col-->
    <!--              align="right"-->
    <!--              justify="right"-->
    <!--            >-->
    <!--              <v-btn bottom depressed rounded class="btnWppAyuda btn-help&#45;&#45;margin" @click="$store.state.redirectToWhastapp()">Ayuda-->
    <!--                <v-img-->
    <!--                  class="imageWppAyuda"-->
    <!--                  src="@/assets/img/icon_whatsapp_ayuda.png"-->
    <!--                  alt="wpp"-->
    <!--                >-->
    <!--                </v-img>-->
    <!--              </v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-footer>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div v-else>-->
    <v-main>
      <v-row align-content="center" justify="center" align="center">
        <v-col cols="12" md="6" sm="6" align-self="center">
          <div class="main-doc__main-img">
            <h2 class="botonBajaDesktop darkPurple">
              {{ clientFullname }}, ¡tu préstamo fue aprobado!
            </h2>
            <v-img
              src="@/assets/img/pet_dni.svg"
              alt="Mascota saludando"
              class="botonBajaDesktop step-main-doc__img--margin"
              contain
              max-height="300"
              min-height="300"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="6"
          align-self="center"
          align="center"
          class="main-doc__right-section"
        >
          <div>
            <p>Validá tu identidad de una manera muy fácil.</p>
            <p>Solo necesitamos las siguientes fotos:</p>
            <ol class="listItems">
              <li v-show="takeDni" class="liMainData">Frente del DNI.</li>
              <li v-show="takeDni" class="liMainData">
                Parte de atrás del DNI.
              </li>
              <li v-show="takeSelfie" class="liMainData">Selfie.</li>
            </ol>
            <p class="subtitle">
              {{ this.$docText["MAIN_TXT"] }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row
        align-content-md="end"
        align-md="end"
        justify-md="end"
        align="center"
        align-content="center"
        justify="center"
      >
        <v-col class="text-center text-md-end" justify="right">
          <v-btn
            bottom
            depressed
            rounded
            x-large
            class="btnAmarillo"
            @click="next()"
          >
            Continuar
          </v-btn>
        </v-col>
      </v-row>
    </v-main>
  </div>
  <!--  </div>-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AutogestionDatosFotos",
  data: () => ({
    valid: true,
    documentaciones: [],
    grupoDocs: [],
    optionsDB: [],
    snackbar: false,
    color: "success",
    timeout: 4000,
    takeSelfie: false,
    takeDni: false,
  }),
  computed: {
    clientFullname() {
      return this.$store.getters.getFullname;
    },
  },
  mounted() {
    this.setStyleStepper("solicitud__stepper-img");
    this.created();
  },
  methods: {
    ...mapGetters(["getNeedStepSelfie", "getNeedStepDni", "getPreffix"]),
    ...mapActions(["setStyleStepper"]),
    back() {
      this.$emit("send-message", "3");
    },
    next() {
      this.setStyleStepper("solicitud__stepper-img--margin");
      this.$emit("send-message", "5");
    },
    created() {
      this.card_index = this.card;
      this.takeDni = this.getNeedStepDni();
      this.takeSelfie = this.getNeedStepSelfie();
      console.log("Componente Datos Fotos creado");
    },
  },
};
</script>
